import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 * BASE API services class
 * WK: for removing duplicated codes
 */
export abstract class BaseAPIService {
  protected baseUrl = ``;
  protected readonly httpOptions = {
    headers: new HttpHeaders({}),
    body: {},
    params: new HttpParams(),
    context: new HttpContext(),
  };
  /**
   * Service URL endpoints
   * in ASSETS > CONTENT > SERVICES
   */
  protected abstract serviceURL: object;

  constructor(protected http: HttpClient) {}

  protected unpackErrorMessage(item) {
    let message = '';
    if (item.constructor === Object) {
      for (const [key, value] of Object.entries(item)) {
        message += `${key} - ${this.unpackErrorMessage(value)}\n`;
      }
    } else if (item.constructor === Array) {
      for (const element of item) {
        message += this.unpackErrorMessage(element) + '\n';
      }
    } else {
      message = item;
    }
    return message;
  }

  protected handleErrorCustomMessage(
    error: HttpErrorResponse,
    message: string,
  ) {
    console.error(`${error.status} - ${error.message}`);
  }

  protected handleErrorSilent(error: HttpErrorResponse) {
    console.error(`${error.status} - ${error.message}`);
  }

  protected handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      // WK: guard to not show any error modal login failed and logout failed
      if (
        error.url.includes('auth/login') ||
        error.url.includes('auth/logout')
      ) {
        // VU: temporary fix to return an error for throwing up the call stack
        // This should not be necessary anymore when we remove login.service dependency on baseAPI
        return error;
      }

      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
      } else {
        this.notify(error);
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      console.error('Handle Client Error (Angular Error, ReferenceError...)');
    }
  }

  notify(error) {
    // Handle request error
    switch (error.status) {
      case 400:
        // Handle Http Error (error.status ===  400)
        if (error.error.error === 'ValidationError') {
          const message = this.unpackErrorMessage(error.error.message);
          // Case the error message is a validation error and it's a nested object
          // with an object in the message.
        } else if (
          error.error.constructor === Object ||
          error.error.constructor === Array
        ) {
          // Case the error message is an object
          const message = this.unpackErrorMessage(error.error);
        }
        break;
      case 401:
        break;
      default:
        // HANDLES ALL UNCAUGHT STATUS (404, 500)
        break;
    }
  }
}
