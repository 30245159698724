import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import menuItemsJson from 'src/app/components/main-menu/menu-items.json';
import { FeaturePermissionService } from '../services/feature-permission.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(
    private featurePermissionService: FeaturePermissionService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const url = route.url[0].path;
    const featurePermissionKey =
      url === '' || url === 'dashboard' ? 'homepage' : url; // featurePermissionMap
    const hasPermission =
      this.featurePermissionService.getRouteAccessPermission(
        featurePermissionKey,
      );

    const routeMenu = menuItemsJson.find((item) => item.route.includes(url));

    if (
      featurePermissionKey === 'homepage' &&
      !hasPermission &&
      routeMenu &&
      this.featurePermissionService.isEudr()
    ) {
      this.router.navigate(['/data/polygon-mapping']);
      return false;
    }

    return true;
  }
}
