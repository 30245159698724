import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import LoginServiceURL from '../../assets/content/services/login-service.json';
import {
  getHttpRequestContext,
  HttpRequestContextOptions,
} from '../interceptors/interceptor-context';
import { LoginRequest } from '../models/api/requests/login';
import { PasswordResetRequest } from '../models/api/requests/password-reset';
import { CompanySettingsResponse } from '../models/api/responses/company-settings';
import { DynamicSettingsResponse } from '../models/api/responses/dynamic-settings';
import { LoginResponse } from '../models/api/responses/login';
import { LogoutResponse } from '../models/api/responses/logout';
import { UserInfoResponse } from '../models/api/responses/user-info';
import { DomainService } from './domain.service';
import { FirebaseAuthService } from './firebase-auth.service';
import { StorageService } from './storage.service';

/**
 * Login Service for HCP Backend
 */
@Injectable({ providedIn: 'root' })
export class LoginService {
  protected serviceURL = LoginServiceURL;
  protected readonly httpOptions = {
    headers: new HttpHeaders({}),
    body: {},
    params: new HttpParams(),
    context: new HttpContext(),
  };
  constructor(
    protected http: HttpClient,
    protected storageService: StorageService,
    private firebaseAuthService: FirebaseAuthService,
    private domainService: DomainService,
  ) {}

  /**
   * POST login request to backend, if the response contains firebaseToken,
   * use that firebaseToken to sign in to firebase auth for access to
   * firebase APIs. Note that in the end, only the Django session token
   * is cached in localStorage. The firebaseToken is cached independently
   * base Firebase Auth API.
   * @param loginRequest data to server
   * @returns Observerable LoginResponse Model
   */
  postLogin(loginRequest: LoginRequest): Observable<LoginResponse> {
    const contextToken: HttpRequestContextOptions = {
      customExceptionEnabled: true,
      disableSound: true,
    };
    this.httpOptions.context = getHttpRequestContext(contextToken);
    return this.http
      .post<LoginResponse>(
        this.serviceURL.loginURL,
        loginRequest,
        this.httpOptions,
      )
      .pipe(
        switchMap((response) => {
          if (response.firebaseToken && !this.domainService.isChina) {
            return defer(() =>
              this.firebaseAuthService.signInWithCustomToken(
                response.firebaseToken,
              ),
            ).pipe(
              map((_) => response),
              catchError((_) => {
                return of(response);
              }),
            );
          } else {
            return of(response);
          }
        }),
        tap((response) => {
          if (response.token) {
            this.storageService.store('Authorization', response);
            this.storageService.store('lastLogin', Date.now());
          }
        }),
      );
  }

  /**
   * POST logout request to backend
   * @returns Observerable LogoutResponse Model
   */
  postLogout(): Observable<LogoutResponse> {
    return this.http.post<LogoutResponse>(
      this.serviceURL.logoutURL,
      null,
      this.httpOptions,
    );
  }
  /**
   * GET User Info from backend
   * @returns Observerable UserInfoResponse Model
   */
  getUserInfo(): Observable<UserInfoResponse> {
    return this.http.get<UserInfoResponse>(
      this.serviceURL.userInfoURL,
      this.httpOptions,
    );
  }

  /**
   * POST Reset Password to backend
   * @param value data to server
   * @returns Observable ANY
   */
  // TODO: add response model
  postResetPassword(value: PasswordResetRequest): Observable<any> {
    // TODO: need to check if need token for password reset request
    return this.http.post<PasswordResetRequest>(
      this.serviceURL.forgotPasswordURL,
      value,
      this.httpOptions,
    );
  }

  /**
   * GET Company Settings from server
   * @returns Observable CompanySettingsResponse Model
   */
  getCompanySetting(): Observable<CompanySettingsResponse> {
    return this.http.get<CompanySettingsResponse>(
      this.serviceURL.companySettingsURL,
      this.httpOptions,
    );
  }

  /**
   * GET singleton settings from server
   * @returns Observable DynamicSettingsResponse Model
   */
  getDynamicSettings(): Observable<DynamicSettingsResponse> {
    return this.http.get<DynamicSettingsResponse>(
      this.serviceURL.dynamicSettingsURL,
      this.httpOptions,
    );
  }
}
