// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageJson from '../../package.json';
export const environment = {
  production: false,
  name: 'Testing',
  appVersion: packageJson.version + '-testing',
  BASE_URL: 'https://api-platform-testing.agridence.com/api/v1/',
  TRACEABILITY_API_BASE_URL:
    'https://traceability-development.agridence.com/api/v1/traceability/service/',
  WS_BASE_URL: 'wss://api-platform-testing.agridence.com/ws/v1/',
  SGX_INSTRUMENT_URL: 'https://streams-development.agridence.com/authv1/',
  MEDIA_BASE_URL: 'https://api-platform-testing.agridence.com',
  PRICE_CHART_URL: 'https://streams-development.agridence.com/authv1/sgx/v1/',
  RR_DASHBOARD_URL: 'https://formsv2admin-infra.agridence.com/',
  POLYGON_MAPPING_URL: 'https://traceability-crd-development.agridence.com/',
  TRACEABILITY_V2_URL: 'https://traceability-v2-development.agridence.com/',
  MAPBOX: {
    ACCESS_TOKEN:
      'pk.eyJ1IjoiYnJ5YW5oYyIsImEiOiJjazYzdjV2N3QwYWl0M25xb2xkcWhldGthIn0.vul8Sx6nMBxcAb94A7Jj1g',
  },
  GOOGLE_TRACKING_ID: 'G-PNB7NCJJ7R',
  MIXPANEL_TOKEN: '46ab397811540d3b0769c15b5a850729',
  GPSNR_FORM_BASE_URL: [
    'https://forms-testing.heveaconnect.com/',
    'https://forms-testing.agridence.com/',
  ],
  FIREBASE: {
    apiKey: 'AIzaSyCCefSqEqw5Vga5YlItyd9vpzmhSlqgZ-0',
    authDomain: 'hcplatform-development.firebaseapp.com',
    projectId: 'hcplatform-development',
    storageBucket: 'hcplatform-development.appspot.com',
    messagingSenderId: '612736903809',
    appId: '1:612736903809:web:74a4ebadab4b59004afb80',
    measurementId: 'G-K2DSKHCGTC',
  },
  timeStamp: '18-09-2024 13:35:14 SGT',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
