import { ToastService } from 'src/app/services/toast.service';
import { Injectable } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Message } from '../message';
import { MessageContainer } from '../message-container';
import { MessageDialogComponent } from '../message-dialog.component';
import { SoundService, SoundTypes } from 'src/app/services/sound.service';
import { StorageService } from 'src/app/services/storage.service';

import { MessageTypes } from '../enum-message';
import { HttpStatusCode } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  message: Message;

  constructor(
    public dialog: MatLegacyDialog,
    private messageContainer: MessageContainer,
    private toastService: ToastService,
    private storageService: StorageService,
    private soundService: SoundService,
  ) {}
  show(message: Message): void {
    // CUSTOM_EXCEPTION_ENABLED to identify how to handle error.
    // 1.Should be true if handle error on component level
    // 2.Otherwise will handled globally.
    this.message = this.messageContainer.get<Message>(message.type);
    this.message.getMessage(message.content);
    if (!message.enableCustomException && !this.message.enableCustomException) {
      this.showMessage(this.message);
    }
    this.playSound(message);
  }
  // later we can have flag to enable or disable console log based on environment.
  logMessage(): void {
    switch (this.message.type) {
      case MessageTypes.Information:
        // eslint-disable-next-line no-console
        console.info(this.message.content);
        break;
      case MessageTypes.Error:
        console.error(this.message.content);
        break;
      case MessageTypes.Warning:
        console.warn(this.message.content);
        break;
      default:
        console.warn(this.message.content);
    }
  }

  getMessage(message: Message): string {
    this.message = this.messageContainer.get<Message>(message.type);
    this.message.getMessage(message.content);
    return this.message.content as string;
  }

  private showMessage(message: Message) {
    if (message.type === HttpStatusCode.Unauthorized) {
      this.showDialog(message);
    } else {
      this.showToast(message);
    }
  }
  private playSound(message: Message) {
    if (!message.disableSound) {
      const enableErrorSound =
        this.storageService.retrieve<boolean>('enableErrorSound');
      if (enableErrorSound) {
        this.soundService.playSound(SoundTypes.error);
      }
    }
  }

  private showDialog(message: Message) {
    if (this.dialog.openDialogs.length >= 1) {
      return;
    }
    this.dialog.open(MessageDialogComponent, {
      data: {
        content: message.content,
        icon: message.icon,
        color: message.color,
        title: message.title,
        close: message.close,
        action: message.action,
        buttonPrimaryText: message.buttonPrimaryText,
        buttonSecondaryText: message.buttonSecondaryText,
      },
    });
  }

  private showToast(message: Message) {
    this.toastService.negativeToast(message.content as string, 5000);
  }
}
